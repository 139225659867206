'use client'
import { useMyTeams, useMyTeamSummary } from 'api/myTeam'
import Link from '@/components/router/Link'
// import { SearchOverlay } from '@/components/search/SearchOverlay'
// import Button from '@/components/ui-other/basic/Button'
import Image, { maxScreenSize } from '@/components/ui-other/basic/Image'
import {
  MyHandballBannerContent,
  MyHandballImage
} from '@/components/ui-other/MyHandballBanner'
import { Heading } from '@/components/ui-other/text/Heading'
import {
  ComponentType,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react'
import { ChevronLeft, ChevronRight, Edit } from 'react-feather'
import checkLogo from 'shared/imageHelpers'
import {
  clubPath,
  gamePath,
  imagePath,
  loginPath,
  myProfilePath,
  signupPath,
  teamPath,
  teamTablePath,
  tournamentSchedulePath
} from 'shared/urlHelpers'
import { GameState, Team } from 'types'
import ListItem from '../ListItem'
import LinkButton from '@/components/router/LinkButton'
import { getAgeGroup } from 'shared/ageGroupHelper'
import { formattedDate, formattedDay, formattedTime } from 'shared/timeHelper'
import { Spinner } from '@/components/ui-other/basic/Spinner'
import { useRouter } from 'next/navigation'
import { PushButton } from '@/components/PushButton'
import { useSession } from 'lib/session-client'
import { useMyTournaments } from 'api/myTournament'

const GridItem: ComponentType<{
  color?: string
  tooltip?: string
  onClick?: () => any
  children?: ReactNode
}> = ({ children, onClick, tooltip, color }) => {
  return (
    <div
      className={`inline-flex items-center justify-center w-12 h-12 p-1 cursor-pointer rounded
        ${
          color === 'primary'
            ? 'bg-primary text-white hover:bg-primary-dark'
            : 'bg-white bg-opacity-90 text-black hover:bg-opacity-100'
        }
        `}
      onClick={onClick}
      data-tooltip-content={tooltip}
      data-tooltip-id="layout-tooltip"
    >
      {children}
    </div>
  )
}

const TeamInfo: ComponentType<{ team: Team | null }> = ({ team }) => {
  const teamSummary = useMyTeamSummary(team)
  return (
    <div className="view-vertical text-black bg-white p-4 space-y-2">
      {team && (
        <>
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 space-x-0 sm:space-x-4 items-start mb-4 sm:items-center overflow-hidden">
            {checkLogo(team.logo) && (
              <Link href={teamPath(team)} tooltip={`Zu ${team.name}`}>
                <Image
                  className="w-16 h-8"
                  src={checkLogo(team.logo)}
                  alt={'Logo'}
                  objectFit="contain"
                  srcSet={[[100, maxScreenSize]]}
                />
              </Link>
            )}
            <div className="w-full pr-4 overflow-hidden">
              <Heading
                level={1}
                size="xl"
                className="flex items-start flex-col"
              >
                <div className="flex truncate w-full">
                  <Link
                    href={teamPath(team)}
                    tooltip={`Zu ${team.name}`}
                    className="truncate"
                  >
                    {team.name}
                  </Link>
                </div>
                {team.defaultTournament?.ageGroup && (
                  <div className=" text-gray-500 text-sm">
                    ({getAgeGroup(team.defaultTournament?.ageGroup)})
                  </div>
                )}
              </Heading>
              {teamSummary?.defaultTournament && (
                <Link
                  href={tournamentSchedulePath(teamSummary.defaultTournament)}
                  className="text-sm text-gray-500"
                  tooltip={`Zu ${teamSummary?.defaultTournament.name}`}
                >
                  {teamSummary?.defaultTournament.name}
                </Link>
              )}
            </div>
            <PushButton channel={`teamgroup.${team.teamGroupId}`} />
          </div>

          <div className="view-vertical">
            <div
              className="
                flex
                flex-col
                space-y-4

                sm:flex-row
                sm:space-x-4
                sm:space-y-0

                lg:flex-col
                lg:space-x-0
                lg:space-y-4

                xl:flex-row
                xl:space-x-4
                xl:space-y-0
            "
            >
              {!teamSummary && (
                <div className="flex items-center justify-center pb-2">
                  <Spinner loading={!teamSummary} />
                </div>
              )}
              {teamSummary?.previousGame && (
                <div className="flex flex-col flex-1 justify-between sm:w-2/3 lg:w-auto xl:w-2/3">
                  <div>
                    <Heading level={4} className="pl-4">
                      Letztes Spiel
                    </Heading>
                    <div className="text-xs text-gray-600 mb-2 pl-4">
                      {teamSummary.previousGame.tournament.name}
                    </div>
                  </div>
                  <Link href={gamePath(teamSummary.previousGame)}>
                    <div className="bg-gray-200 h-24 rounded p-4 flex flex-col justify-center space-y-2">
                      <div className="flex items-center space-x-2">
                        <div className="w-6 h-6 flex-shrink-0">
                          <Image
                            className="w-full h-full overflow-auto"
                            src={checkLogo(
                              teamSummary.previousGame.homeTeam.logo,
                              'club'
                            )}
                            alt={`Logo ${teamSummary.previousGame.homeTeam.name}`}
                            objectFit="contain"
                            srcSet={[[100, maxScreenSize]]}
                          />
                        </div>
                        <div className="flex-1">
                          {teamSummary.previousGame.homeTeam.name}
                        </div>
                        <div className="font-bold">
                          {teamSummary.previousGame.homeGoals}
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="w-6 h-6 flex-shrink-0">
                          <Image
                            className="w-full h-full overflow-auto"
                            src={checkLogo(
                              teamSummary.previousGame.awayTeam.logo,
                              'club'
                            )}
                            alt={`Logo ${teamSummary.previousGame.awayTeam.name}`}
                            objectFit="contain"
                            srcSet={[[100, maxScreenSize]]}
                          />
                        </div>
                        <div className="flex-1">
                          {teamSummary.previousGame.awayTeam.name}
                        </div>
                        <div className="font-bold">
                          {teamSummary.previousGame.awayGoals}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              )}
              {teamSummary?.nextGame && (
                <div
                  className={`flex flex-col sm:w-1/3 lg:w-auto xl:w-1/3 justify-between ${
                    !teamSummary.previousGame ? 'flex-1' : ''
                  }`}
                >
                  <div>
                    <Heading
                      level={4}
                      className={`pl-4 ${
                        !teamSummary.previousGame ? 'text-center' : ''
                      }`}
                    >
                      {teamSummary.nextGame.state === GameState.LIVE && (
                        <div className="flex items-center space-x-2">
                          <span className="flex h-3 w-3 relative">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-light opacity-75"></span>
                            <span className="block rounded-full h-3 w-3 bg-primary"></span>
                          </span>
                          <span>Jetzt Live!</span>
                        </div>
                      )}
                      {teamSummary.nextGame.state !== GameState.LIVE && (
                        <span>Nächstes Spiel</span>
                      )}
                    </Heading>
                    <div className="text-xs text-gray-600 mb-2 pl-4">
                      {teamSummary.nextGame.tournament.name}
                    </div>
                  </div>
                  <Link href={gamePath(teamSummary.nextGame)}>
                    <div
                      className={`bg-gray-200 h-24 rounded p-4 flex items-center justify-center space-x-2 ${
                        teamSummary.nextGame.state === GameState.LIVE
                          ? 'my-team-summary-live'
                          : ''
                      }`}
                    >
                      <div className="w-10 h-10 flex-shrink-0 my-team-summary-image">
                        <Image
                          className="w-full h-full overflow-auto"
                          src={checkLogo(
                            teamSummary.nextGame.homeTeam.logo,
                            'club'
                          )}
                          alt={`Logo ${teamSummary.nextGame.homeTeam.name}`}
                          objectFit="contain"
                          srcSet={[[100, maxScreenSize]]}
                        />
                      </div>
                      {teamSummary.nextGame.state === GameState.LIVE && (
                        <div className="flex flex-col items-center justify-center my-team-summary-live-result">
                          {teamSummary.nextGame.homeGoals}:
                          {teamSummary.nextGame.awayGoals}
                        </div>
                      )}
                      {teamSummary.nextGame.state !== GameState.LIVE && (
                        <div className="flex flex-col items-center justify-center">
                          <span className="text-sm font-bold">
                            {formattedDay(teamSummary.nextGame.startsAt)}
                          </span>
                          <span className="text-xs">
                            {formattedDate(teamSummary.nextGame.startsAt)}
                          </span>
                          <span className="text-xs">
                            {formattedTime(teamSummary.nextGame.startsAt)}
                          </span>
                        </div>
                      )}
                      <div className="w-10 h-10 flex-shrink-0 my-team-summary-image">
                        <Image
                          className="w-full h-full overflow-auto"
                          src={checkLogo(
                            teamSummary.nextGame.awayTeam.logo,
                            'club'
                          )}
                          alt={`Logo ${teamSummary.nextGame.awayTeam.name}`}
                          objectFit="contain"
                          srcSet={[[100, maxScreenSize]]}
                        />
                      </div>
                    </div>

                    {/* <ScheduleListItem
                      key={teamSummary.id}
                      game={teamSummary.nextGame}
                    /> */}
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 xs:grid-cols-3 gap-2">
            <LinkButton
              href={teamPath(team)}
              className="flex-1"
              buttonClassName="w-full btn-short"
            >
              Spieltag
            </LinkButton>
            <LinkButton
              href={teamTablePath(team)}
              className="flex-1"
              buttonClassName="w-full btn-short"
            >
              Tabelle
            </LinkButton>
            {team.club && (
              <LinkButton
                href={clubPath(team.club)}
                className="flex-1  col-span-2 xs:col-span-1"
                buttonClassName="w-full btn-short"
              >
                Zum Verein
              </LinkButton>
            )}
          </div>
        </>
      )}
    </div>
  )
}

const MyTeamSummaryEmpty: ComponentType<{ className: string }> = ({
  className
}) => {
  return (
    <>
      <p
        className={`text-3xl uppercase font-bold text-center leading-relaxed ${className}`}
      >
        SPIELPLÄNE, LIVETICKER & VIELES MEHR:
        <br />
        FOLGE DEINEN TEAMS
      </p>
      <div className="w-full sm:w-2/3 md:w-2/3 mx-auto">
        <img
          src={imagePath(
            'handball_net_mein_handball_vereine_logos.webp',
            'banner'
          )}
          className="w-full h-full"
        />
      </div>

      <div className="mt-8  view-horizontal justify-center">
        <LinkButton
          href={`${signupPath()}?utm_source=startseite_unangemeldet&utm_medium=button&utm_campaign=registrieren`}
        >
          REGISTRIEREN UND VORTEILE SICHERN
        </LinkButton>
      </div>
    </>
  )
}

export const MyTeamSummary: ComponentType = () => {
  const [session] = useSession()

  const router = useRouter()

  const teams = useMyTeams(session)
  const tournaments = useMyTournaments(session)
  const [activeTeam, setActiveTeam] = useState<Team | null>(null)
  const showTeamGrid =
    (teams && teams.length > 0) || (tournaments && tournaments.length > 0)
  useEffect(() => {
    if (showTeamGrid) {
      if (teams && teams.length > 0) {
        setActiveTeam(teams[0])
      }
    }
  }, [teams, showTeamGrid, tournaments])

  const onPrevTeam = useCallback(() => {
    if (teams && activeTeam) {
      const currentIndex = teams?.indexOf(activeTeam)
      if (currentIndex === 0) setActiveTeam(teams[teams.length - 1])
      else setActiveTeam(teams[currentIndex - 1])
    }
  }, [teams, activeTeam])

  const onNextTeam = useCallback(() => {
    if (teams && activeTeam) {
      const currentIndex = teams?.indexOf(activeTeam)
      setActiveTeam(teams[(currentIndex + 1) % teams.length])
    }
  }, [teams, activeTeam])
  // SWIPE
  // const [touchStart, setTouchStart] = useState(null)
  // const [touchEnd, setTouchEnd] = useState(null)
  //
  // const minSwipeDistance = 50
  //
  // const onTouchStart = (e: any) => {
  //   setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
  //   setTouchStart(e.targetTouches[0].clientX)
  // }
  //
  // const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX)
  //
  // const onTouchEnd = () => {
  //   if (!touchStart || !touchEnd) return
  //   const distance = (touchStart ?? 0) - (touchEnd ?? 0)
  //   const isLeftSwipe = distance > minSwipeDistance
  //   const isRightSwipe = distance < -minSwipeDistance
  //   if (isLeftSwipe) onNextTeam()
  //   if (isRightSwipe) onPrevTeam()
  // }

  return (
    <MyHandballImage>
      {session && <MyHandballBannerContent />}
      {showTeamGrid ? (
        <div className="md:flex flex-wrap md:flex-nowrap md:space-x-6 items-start content-between">
          <div className="flex flex-wrap md:flex-col gap-4 mt-6">
            <div>
              <div className="font-semibold text-xs h-5">
                <Link
                  href={
                    session ? `${myProfilePath()}#teams-a` : `${loginPath()}`
                  }
                  className="hover:text-current hover:opacity-75 whitespace-nowrap"
                >
                  Meine Teams
                  <Edit className="w-3 h-3 inline-block align-baseline ml-2" />
                </Link>
              </div>
              <div className="flex flex-wrap  md:grid grid-flow-col-dense md:grid-flow-row md:grid-cols-2 gap-2 mt-1">
                {teams?.map((t) => (
                  <GridItem
                    key={t.id}
                    onClick={() => setActiveTeam(t)}
                    tooltip={`${t.name} ${t.defaultTournament?.name}`}
                  >
                    <Image
                      className="h-full w-full"
                      objectFit="contain"
                      src={checkLogo(t.logo, 'club')}
                      alt={t.name}
                    />
                  </GridItem>
                ))}
                {teams && teams.length <= 0 && (
                  <GridItem color="primary" tooltip="Team hinzufügen">
                    {/* FIXME */}
                    {/* <SearchOverlay
                      searchElement={(p) => <Plus {...p} />}
                      defaultActiveTab="clubs"
                      showAddTeamButton
                    /> */}
                  </GridItem>
                )}
              </div>
            </div>
            <div className="md:block hidden">
              <div className="font-semibold text-xs h-5">
                <Link
                  href={
                    session ? `${myProfilePath()}#ligen-a` : `${loginPath()}`
                  }
                  className="hover:text-current hover:opacity-75 whitespace-nowrap"
                >
                  Meine Ligen
                  <Edit className="w-3 h-3 inline-block align-baseline ml-2" />
                </Link>
              </div>
              <div className="flex flex-wrap  md:grid grid-flow-col-dense md:grid-flow-row md:grid-cols-2 gap-2 mt-1">
                {tournaments?.map((t) => (
                  <GridItem
                    key={t.id}
                    onClick={() => router.push(tournamentSchedulePath(t))}
                    tooltip={`${t.name} ${t.organization?.name}`}
                  >
                    <Image
                      className="h-full w-full"
                      objectFit="contain"
                      src={checkLogo(t.logo, 'club')}
                      alt={t.name}
                    />
                  </GridItem>
                ))}
                {tournaments && tournaments?.length <= 0 && (
                  <GridItem color="primary" tooltip="Liga hinzufügen">
                    {/* FIXME */}
                    {/* <SearchOverlay
                      searchElement={(p) => <Plus {...p} />}
                      defaultActiveTab="tournaments"
                      showAddTeamButton
                    /> */}
                  </GridItem>
                )}
              </div>
            </div>
          </div>

          <div className="mt-6 md:pt-6 flex-grow min-w-0">
            {teams && teams.length === 0 && <MyTeamSummaryEmpty className="" />}
            <div className="flex">
              {teams && teams.length > 1 && (
                <button
                  className="bg-primary-darker hover:bg-primary text-white flex items-center rounded-l px-1"
                  onClick={onPrevTeam}
                >
                  <ChevronLeft className="h-5 w-5" />
                </button>
              )}
              <div
                className="min-w-0 flex-1"
                // onTouchStart={onTouchStart}
                // onTouchMove={onTouchMove}
                // onTouchEnd={onTouchEnd}
              >
                {activeTeam && (
                  <TeamInfo key={activeTeam.id} team={activeTeam} />
                )}
              </div>
              {teams && teams.length > 1 && (
                <button
                  className="bg-primary-darker hover:bg-primary text-white flex items-center rounded-r px-1"
                  onClick={onNextTeam}
                >
                  <ChevronRight className="h-5 w-5" />
                </button>
              )}
            </div>
            {teams && teams.length > 1 && (
              <div className="view-horizontal space-x-2 justify-center mt-2">
                {teams.map((t, i) =>
                  t === activeTeam ? (
                    <button
                      key={t.id}
                      className="w-16 cursor-pointer text-primary py-2"
                    >
                      <div className="h-1 bg-current"></div>
                    </button>
                  ) : (
                    <button
                      key={t.id}
                      className="w-16 cursor-pointer text-white opacity-75 hover:opacity-100"
                      onClick={() => setActiveTeam(teams[i])}
                    >
                      <div className="h-1 bg-current"></div>
                    </button>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <MyTeamSummaryEmpty className="mt-16" />
      )}

      <div className="md:hidden block mt-4">
        {session && (
          <div className="font-semibold text-xs h-5">
            <Link
              href={session ? `${myProfilePath()}#ligen-a` : `${loginPath()}`}
              className="hover:text-current hover:opacity-75 whitespace-nowrap"
            >
              Meine Ligen
              <Edit className="w-3 h-3 inline-block align-baseline ml-2" />
            </Link>
          </div>
        )}
        {tournaments && (
          <div className="flex view-vertical mt-1 text-bodyColor p-4 rounded bg-white">
            {tournaments?.map((t) => (
              <ListItem
                key={t.id}
                logo={checkLogo(t.logo, 'club')}
                title={t.name}
                url={tournamentSchedulePath(t)}
                text={t.organization?.name}
              />
            ))}
            {tournaments && tournaments?.length <= 0 && (
              <GridItem color="primary" tooltip="Liga hinzufügen">
                {/* FIXME */}
                {/* <SearchOverlay
                  searchElement={(p) => <Plus {...p} />}
                  defaultActiveTab="tournaments"
                  showAddTeamButton
                /> */}
              </GridItem>
            )}
          </div>
        )}
      </div>
    </MyHandballImage>
  )
}
