'use client'

import React, { ComponentType } from 'react'
import { Heading } from '@/components/ui-other/text/Heading'
import { News } from 'types'
import {
  NewsItemHorizontal,
  NewsItemVertical
} from '@/components/videocenter/NewsList'
import { videocenterPath } from 'shared/urlHelpers'
import LinkButton from '@/components/router/LinkButton'
import Link from '@/components/router/Link'
import { checkImage } from 'shared/imageHelpers'
import NewsMeta from '@/components/news/NewsMeta'
import Image, { maxScreenSize } from '@/components/ui-other/basic/Image'
import { PlayCircle } from 'react-feather'
import { isTabletScreen } from 'shared/domHelpers'

const VideocenterPreview: ComponentType<{
  news: News[]
  highlight: string
  hideButton?: boolean
}> = ({ news, highlight, hideButton = false }) => {
  if (!news.length) return null
  const showedItems = news.slice(0, 4)
  const highlightedItem =
    showedItems.filter((n) =>
      n.tags.map((t) => t.name).includes(highlight)
    )[0] || showedItems[0]
  const otherNews = showedItems.filter((n) => n.id !== highlightedItem.id)

  const isTablet = isTabletScreen()
  return (
    <>
      <Heading level={2} border className="text-white">
        Livestream- und Videocenter
      </Heading>

      {isTablet ? (
        <div className="">
          {highlightedItem && (
            <Link
              href={videocenterPath(news[0])}
              className="view-vertical hover:text-current"
            >
              <div className="relative flex items-center justify-center">
                <Image
                  src={checkImage(news[0].feature_image)}
                  className="w-full aspect-w-16 aspect-h-9 rounded overflow-hidden shadow"
                  srcSet={[[600, maxScreenSize]]}
                />
                <PlayCircle
                  size="4em"
                  strokeWidth="1px"
                  className="absolute shadow-lg"
                />
              </div>
              <div className="view-vertical">
                <NewsMeta news={news[0]} className="my-4" />
                <Heading level={3} weight="medium" size="base" className="mb-0">
                  {news[0].title}
                </Heading>
                <div className="block w-24 h-0.5 bg-primary my-6"></div>
              </div>
            </Link>
          )}

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4 auto-rows-auto gap-8 mb-8">
            {otherNews.map((n) => (
              <NewsItemVertical key={n.id} news={n} video />
            ))}
          </div>
        </div>
      ) : (
        <div className="view-vertical">
          <div className="flex space-x-0 md:space-x-8 mb-10">
            <div className="flex-1 ">
              {highlightedItem && (
                <Link
                  href={videocenterPath(news[0])}
                  className="flex items-center justify-center flex-col relative w-full hover:text-white"
                >
                  <div className="w-full flex justify-center items-center">
                    <Image
                      src={checkImage(news[0].feature_image)}
                      className="w-full aspect-w-16 aspect-h-9 rounded overflow-hidden shadow"
                      srcSet={[[600, maxScreenSize]]}
                    />
                    <PlayCircle
                      size="4em"
                      strokeWidth="1px"
                      className="absolute shadow-lg"
                    />
                  </div>
                  <div className=" bottom-0 bg-blue-dark w-full">
                    <NewsMeta news={news[0]} className="my-4" />
                    <Heading
                      level={3}
                      weight="medium"
                      size="base"
                      className="mb-0"
                    >
                      {news[0].title}
                    </Heading>
                  </div>
                </Link>
              )}
            </div>
            <div className="flex-1 w-5/12 space-y-8">
              {otherNews.map((n) => (
                <NewsItemHorizontal key={n.id} news={n} video />
              ))}
            </div>
          </div>
        </div>
      )}

      {!hideButton && (
        <LinkButton
          href={videocenterPath()}
          color="white"
          className="grid justify-items-center"
          buttonClassName="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/3"
        >
          Zum Video-Center
        </LinkButton>
      )}
    </>
  )
}

export default VideocenterPreview
