import React, { ComponentType } from 'react'
import { Game, GameState } from 'types'
import Image, { maxScreenSize } from '@/components/ui-other/basic/Image'
import {
  formattedTime,
  formattedDateFullShort,
  formattedDay
} from 'shared/timeHelper'
import checkLogo from 'shared/imageHelpers'

const ScheduleListItem: ComponentType<{
  game: Game
  showFullDate?: boolean
}> = ({ game, showFullDate = true }) => {
  return (
    <div className="schedule-list-item">
      <div className="schedule-list-item-teams">
        <div
          className="schedule-list-item-team-wrapper mb-2"
          data-tooltip-content={game.homeTeam.name}
          data-tooltip-id="layout-tooltip"
        >
          <span className="schedule-list-item-logo-wrapper">
            <Image
              className="w-full h-full"
              src={checkLogo(game.homeTeam.logo, 'club')}
              alt={`Logo ${game.homeTeam.name}`}
              objectFit="contain"
              srcSet={[[100, maxScreenSize]]}
            />
          </span>
          <span className="schedule-list-item-team">{game.homeTeam.name}</span>
        </div>
        <div
          className="schedule-list-item-team-wrapper"
          data-tooltip-content={game.awayTeam.name}
          data-tooltip-id="layout-tooltip"
        >
          <span className="schedule-list-item-logo-wrapper">
            <Image
              className="w-full h-full"
              src={checkLogo(game.awayTeam.logo, 'club')}
              alt={`Logo ${game.awayTeam.name}`}
              objectFit="contain"
              srcSet={[[100, maxScreenSize]]}
            />
          </span>
          <span className="schedule-list-item-team">{game.awayTeam.name}</span>
        </div>
      </div>
      {game.state === GameState.PRE && (
        <div className="schedule-list-item-starts-at">
          {game.showTime && (
            <>
              <div className="schedule-list-item-date mb-2 uppercase font-semibold">
                {showFullDate && formattedDateFullShort(game.startsAt)}
                {!showFullDate && formattedDay(game.startsAt)}
              </div>
              <div className="schedule-list-item-time">
                {formattedTime(game.startsAt)} Uhr
              </div>
            </>
          )}
        </div>
      )}
      {game.state === GameState.LIVE && (
        <div className="schedule-list-item-starts-at live">
          <div className="schedule-list-item-score">{game.homeGoals}</div>
          <div className="schedule-list-item-score">{game.awayGoals}</div>
        </div>
      )}
      {game.state === GameState.POST && (
        <div className="schedule-list-item-starts-at post">
          <div className="schedule-list-item-score">{game.homeGoals}</div>
          <div className="schedule-list-item-score">{game.awayGoals}</div>
        </div>
      )}
      {(game.state === GameState.POSTPONED ||
        game.state === GameState.CANCELED) && (
        <div className="schedule-list-item-starts-at postponed">
          <div className="schedule-list-item-score">{game.homeGoals}</div>
          <div className="schedule-list-item-score">{game.awayGoals}</div>
        </div>
      )}
    </div>
  )
}

export default ScheduleListItem
